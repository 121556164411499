// prettier-ignore
let dictionary = {
  ERROR_ENTER_NAME: 'Por favor, ingrese su nombre',
  ERROR_ENTER_PASSWORD: 'Por favor, ingrese su contraseña',
  ERROR_USER_NOT_FOUND: 'Usuario no encontrado, por favor verifique sus credenciales',
  LABEL_ADVERTISING_AGE: 'Edad',
  LABEL_ADVERTISING_ALERT_PICTURE: 'Por favor, confirma si deseas eliminar tu imagen',
  LABEL_ADVERTISING_CITY: 'Ciudad',
  LABEL_ADVERTISING_COUNTRY: 'País',
  LABEL_ADVERTISING_GENDER: 'Género',
  LABEL_ADVERTISING_REGION: 'Región',
  LABEL_ADVERTISING_TITLE_PICTURE: 'Eliminar imagen',
  LABEL_ADVERTISING_WARNING_CHOOSE: 'Debes elegir una opción',
  LABEL_ADVERTISING_WARNING_LOAD_IMAGE: 'Por favor, sube una imagen',
  LABEL_ADVERTISING_WEBSITE: 'Sitio web',
  LABEL_BUTTON_BACK: 'Atrás',
  LABEL_BUTTON_CANCEL: 'Cancelar',
  LABEL_BUTTON_CONFIRM: 'Confirmar',
  LABEL_BUTTON_CONTACT: 'Contáctenos',
  LABEL_BUTTON_DELETE: 'Eliminar',
  LABEL_BUTTON_RETRIEVE_PASSWORD: 'Recuperar contraseña',
  LABEL_BUTTON_SAVE: 'Guardar',
  LABEL_BUTTON_SIGNIN: 'Iniciar sesión',
  LABEL_COMPANIES_ALERT_DELETE_CONTENT: 'Vas a eliminar este negocio, por favor confirma',
  LABEL_COMPANIES_ALERT_DELETE_TITLE: 'Eliminar un negocio',
  LABEL_COMPANIES_CHANGE_PICTURE:'Cambiar la imagen',
  LABEL_COMPANIES_CODE_ERROR: 'Código debe ser 3 letras majuscule con 4 cifras',
  LABEL_COMPANIES_ERROR_IMAGE_SIZE:'Tu imagen debe tener al menos {0}px larga x {1}px alta, el tamaño de la imagen que indicaste es de {2}px larga x {3}px alta',
  LABEL_COMPANIES_FEEDBACK: 'Feedback',
  LABEL_COMPANIES_FEEDBACK_TYPE: 'Determina si se activan los feedback',
  LABEL_COMPANIES_GIFT_EXPIRATION_ERROR: 'Debe ser un numero superior a 3 días',
  LABEL_COMPANIES_HEADER_ACTION: 'Acción',
  LABEL_COMPANIES_HEADER_CODE: 'Código',
  LABEL_COMPANIES_HEADER_ID: 'Id',
  LABEL_COMPANIES_HEADER_NAME: 'Nombre negocio',
  LABEL_COMPANIES_HEADER_NUM_SCAN: 'Número de escaneo',
  LABEL_COMPANIES_HEADER_TYPE: 'Tipo',
  LABEL_COMPANIES_NAME_COMPANY_PICTURE:'El nombre de tu empresa',
  LABEL_COMPANIES_NAME_ERROR: 'El nombre debe tener entre 5 y 60 caracteres y contener solo letras, cifras o espacios',
  LABEL_COMPANIES_NO_PICTURE:'No hay imagen',
  LABEL_COMPANIES_PICTURE_MINIMUM:'Por favor sube una imagen (mínimo {0}px de largo x {1}px alto)',
  LABEL_COMPANIES_POINT_EXPIRATION_ERROR: 'Debe ser un numero superior a 3 días',
  LABEL_COMPANIES_PRIVATE: 'Privado',
  LABEL_COMPANIES_PUBLIC: 'Publico',
  LABEL_COMPANIES_REQUIREMENT_ERROR:'Indica solo letras, cifras o espacios',
  LABEL_COMPANIES_SEARCH: 'Buscar negocios',
  LABEL_COMPANIES_TOOLTIP_CODE:'Indica el código de este negocio.',
  LABEL_COMPANIES_TOOLTIP_FACEBOOK: 'Por favor indica un vinculo de esa forma https://www.facebook.com/Fidelize',
  LABEL_COMPANIES_TOOLTIP_GIFT_EXPIRATION:'Indica cuanto tiempo van a tener tus clientes para canjear su regalo',
  LABEL_COMPANIES_TOOLTIP_INSTAGRAM: 'Por favor indica un vinculo de esa forma https://instagram.com/fidelize.chile',
  LABEL_COMPANIES_TOOLTIP_NAME: 'Elije un nombre para tu empresa, este nombre se verá en la red Fidelize',
  LABEL_COMPANIES_TOOLTIP_POINT_EXPIRATION:'Indica el tiempo de inactividad necesario para perder un punto.',
  LABEL_COMPANIES_TOOLTIP_REQUIREMENT:'Indica la condición para acumular un punto.',
  LABEL_COMPANIES_TOOLTIP_TYPE: 'Determina si se podrá ver la empresa en la lista de la app',
  LABEL_COMPANIES_URL_FACEBOOK_ERROR:'Por favor indica un vinculo de esa forma https://www.facebook.com/Fidelize',
  LABEL_COMPANIES_URL_INSTAGRAM_ERROR:'Por favor indica un vinculo de esa forma https://www.instagram.com/Fidelize',
  LABEL_CONNECTED_AS:'Estás conectado como {0}',
  LABEL_DASHBOARD_ACTIVE_CLIENTS: 'Clientes activos',
  LABEL_DASHBOARD_ACTIVE_CLIENTS_INFO: 'Clientes activos en el último mes',
  LABEL_DASHBOARD_AGE_DISTRIBUTION: 'Distribución por edad',
  LABEL_DASHBOARD_GENDER_DISTRIBUTION: 'Distribución por género',
  LABEL_DASHBOARD_GIFTS_GIVEN_W: 'Regalos otorgados (Semanal)',
  LABEL_DASHBOARD_MAN: 'Hombre',
  LABEL_DASHBOARD_NEW_CLIENTS: 'Nuevos clientes',
  LABEL_DASHBOARD_NEW_CLIENTS_INFO: 'Nuevos clientes en el último mes',
  LABEL_DASHBOARD_NOT_DEFINED: 'No definido',
  LABEL_DASHBOARD_NO_DATA: 'Sin datos',
  LABEL_DASHBOARD_NUMBER_OF_CLIENTS: 'Número de clientes',
  LABEL_DASHBOARD_NUM_USUARIOS: 'Numero de usuarios',
  LABEL_DASHBOARD_NUM_USUARIOS_INFO: 'Numero total de usuarios desde el principio',
  LABEL_DASHBOARD_POINTS_GIVEN: 'Puntos otorgados',
  LABEL_DASHBOARD_POINTS_GIVEN_W: 'Puntos otorgados (Semanal)',
  LABEL_DASHBOARD_TOTAL_CLIENTS: 'Clientes total',
  LABEL_DASHBOARD_TOTAL_CLIENTS_INFO: 'Clientes total desde el principio',
  LABEL_DASHBOARD_W: 'S',
  LABEL_DASHBOARD_WOMAN: 'Mujer',
  LABEL_FEEDBACKS_HEADER_DATE: 'Fecha',
  LABEL_FEEDBACKS_HEADER_MESSAGE: 'Mensaje',
  LABEL_FEEDBACKS_HEADER_NOTE: 'Nota',
  LABEL_FEEDBACKS_HEADER_USER: 'Nombre de usuario',
  LABEL_FEEDBACKS_SEARCH: 'Buscar retroalimentaciones',
  LABEL_IMAGE_REQUIREMENT: 'Tu imagen debe tener una relación de aspecto 2/3 (vertical) o 3/2 (horizontal) y menos de 2MB',
  LABEL_INPUT_ANSWER: 'Respuesta',
  LABEL_INPUT_CARD_EXPIRATION: 'Expiración de la tarjeta (días)',
  LABEL_INPUT_CODE: 'Código',
  LABEL_INPUT_FACEBOOK_URL: 'URL de Facebook',
  LABEL_INPUT_GIFT_EXPIRATION: 'Expiración del regalo (días)',
  LABEL_INPUT_IMAGE: 'Imagen',
  LABEL_INPUT_INSTAGRAM_URL: 'URL de Instagram',
  LABEL_INPUT_MAIL: 'Correo',
  LABEL_INPUT_NAME: 'Nombre',
  LABEL_INPUT_NO_IMAGE: 'No hay imagen',
  LABEL_INPUT_PASSWORD: 'Contraseña',
  LABEL_INPUT_REQUIREMENT: 'Requisito',
  LABEL_INPUT_USERNAME: 'Nombre de usuario',
  LABEL_LOCALS_ADDRESS:'Dirección',
  LABEL_LOCALS_ADDRESS_ERROR: 'La dirección debe tener entre 5 y 60 caracteres y contener solo letras, cifras o espacios',
  LABEL_LOCALS_ADDRESS_ID:'Address Id',
  LABEL_LOCALS_ADDRESS_ID_ERROR: 'Debe ser un id de google maps, por ejemplo AKHwGeGYTcMbo5Hn6',
  LABEL_LOCALS_ADDRESS_ID_TOOLTIP: 'Recupera el address id de la pagine google',
  LABEL_LOCALS_ADDRESS_TOOLTIP: 'La dirección como va aparecer en la app',
  LABEL_LOCALS_ALERT_DELETE_CONTENT: 'Vas a eliminar este dirección, por favor confirma',
  LABEL_LOCALS_ALERT_DELETE_TITLE: 'Eliminar una dirección',
  LABEL_LOCALS_HEADER_ACTIONS: 'Acción',
  LABEL_LOCALS_HEADER_ADDRESS: 'Dirección',
  LABEL_LOCALS_HEADER_NAME: 'Nombre visible',
  LABEL_LOCALS_HEADER_PHONE_NUMBER: 'Número de teléfono',
  LABEL_LOCALS_NAME:'Nombre',
  LABEL_LOCALS_NAME_ERROR: 'El nombre debe tener entre 5 y 30 caracteres y contener solo letras, cifras o espacios',
  LABEL_LOCALS_NAME_TOOLTIP: 'El nombre que se va a ver en la app por este local',
  LABEL_LOCALS_PHONE:'Teléfono',
  LABEL_LOCALS_PHONE_ERROR: 'El teléfono de contacto debe ser de forma tel:+56973123570 o whatsapp:+56973123570',
  LABEL_LOCALS_PHONE_TOOLTIP: 'El teléfono de contacto por este local, por favor usa whatsapp: o tel:',
  LABEL_LOCALS_SEARCH: 'Buscar locales',
  LABEL_NO:'No',
  LABEL_NOTIFICATION_ACTION: 'Acción',
  LABEL_NOTIFICATION_ALERT_DELETE_CONTENT: 'Vas a eliminar esta notificación, por favor confirma',
  LABEL_NOTIFICATION_ALERT_DELETE_TITLE: 'Eliminar una notificación',
  LABEL_NOTIFICATION_ANSWER: 'Respuesta',
  LABEL_NOTIFICATION_ANSWER_DESC: 'Indica posibles respuestas',
  LABEL_NOTIFICATION_ANSWER_ERROR: 'Debes indicar entre 2 y 5 respuestas (con 5 hasta 20 caracteres)',
  LABEL_NOTIFICATION_CONTENT: 'Contenido',
  LABEL_NOTIFICATION_CONTENT_DESC: 'Luego escribe el contenido',
  LABEL_NOTIFICATION_CONTENT_ERROR: 'El contenido debe tener entre 10 y 100 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_CREATED: 'La notificación ha sido creada',
  LABEL_NOTIFICATION_DATE: 'Fecha',
  LABEL_NOTIFICATION_DATE_DESC: 'Y selecciona la fecha y hora en la que deseas enviarla',
  LABEL_NOTIFICATION_DATE_ERROR: 'Por favor, selecciona una fecha y hora para enviar notificaciones',
  LABEL_NOTIFICATION_NO_DATE: 'Sin fecha',
  LABEL_NOTIFICATION_NO_NOTIFICATION: 'No hay notificaciones, por favor crea la primera',
  LABEL_NOTIFICATION_STANDARD: 'Estándar',
  LABEL_NOTIFICATION_STATUS: 'Estado',
  LABEL_NOTIFICATION_SURVEY: 'Encuesta',
  LABEL_NOTIFICATION_TITLE: 'Título',
  LABEL_NOTIFICATION_TITLE_DESC: 'Por favor, elige un título para la notificación',
  LABEL_NOTIFICATION_TITLE_ERROR: 'El título debe tener entre 5 y 60 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_TOOLTIP_ANSWER: 'Presiona la tecla enter/return para agregar tu respuesta',
  LABEL_NOTIFICATION_TOOLTIP_DATE: 'No puedes elegir en la próxima hora, por favor indica algo después',
  LABEL_NOTIFICATION_TOOLTIP_TYPE: 'El tipo estándar es un mensaje de notificación simple, las encuestas son preguntas y necesitarás indicar posibles respuestas',
  LABEL_NOTIFICATION_TYPE: 'Tipo',
  LABEL_NOTIFICATION_UPDATED: 'La notificación ha sido actualizada',
  LABEL_NO_RESULTS:'No hay resultados',
  LABEL_PRICING_BASIC_FEATURE_1: 'Acceso al módulo de administración',
  LABEL_PRICING_BASIC_FEATURE_2: 'Gestiona la configuración de tu empresa',
  LABEL_PRICING_BASIC_FEATURE_3: 'Gestiona tus usuarios (máx. 1 usuario)',
  LABEL_PRICING_BASIC_FEATURE_4: 'Visualiza tus retroalimentaciones',
  LABEL_PRICING_BASIC_FEATURE_5: 'Soporte básico',
  LABEL_PRICING_BASIC_TITLE: 'Plan Básico',
  LABEL_PRICING_CHOSE_PLAN: 'Elige tu plan mensual',
  LABEL_PRICING_OBJECT_INTERESTED: 'Interesado en',
  LABEL_PRICING_PREMIUM_FEATURE_1: 'Incluye el plan estándar',
  LABEL_PRICING_PREMIUM_FEATURE_2: 'Hasta 3 Locales',
  LABEL_PRICING_PREMIUM_FEATURE_3: '4 Notificaciones',
  LABEL_PRICING_PREMIUM_FEATURE_4: '1 Encuesta',
  LABEL_PRICING_PREMIUM_FEATURE_5: 'Análisis avanzados (Distribución por género y edad)',
  LABEL_PRICING_PREMIUM_FEATURE_6: 'Publicidad en la red Fidelize hasta 2000 impresiones',
  LABEL_PRICING_PREMIUM_FEATURE_7: 'Soporte prioritario',
  LABEL_PRICING_PREMIUM_TITLE: 'Plan Premium',
  LABEL_PRICING_SCALED_PLAN: 'Plan escalado',
  LABEL_PRICING_STANDARD_FEATURE_1: 'Incluye el plan básico',
  LABEL_PRICING_STANDARD_FEATURE_2: 'Número ilimitado de usuarios',
  LABEL_PRICING_STANDARD_FEATURE_3: 'Hasta 1 Local',
  LABEL_PRICING_STANDARD_FEATURE_4: '1 Notificación',
  LABEL_PRICING_STANDARD_FEATURE_5: 'Análisis básicos (Puntos, Regalos, Clientes activos, Nuevos clientes)',
  LABEL_PRICING_STANDARD_FEATURE_6: 'Publicidad en la red Fidelize hasta 1000 impresiones',
  LABEL_PRICING_STANDARD_FEATURE_7: 'Soporte estándar',
  LABEL_PRICING_STANDARD_TITLE: 'Plan Estándar',
  LABEL_RESET_DEFINE_DONE:'La contraseña ha sido cambiada exitosamente, te vamos a redirigir para identificarte',
  LABEL_RESET_DEFINE_PASSWORD_CONTENT:'Por favor indica tu nueva contraseña y repítela por favor',
  LABEL_RESET_DEFINE_PASSWORD_TITLE:'Definir nueva contraseña',
  LABEL_RESET_ERROR_VERIFY:'Por favor verifica los campos',
  LABEL_RESET_GLOBAL_ERROR: 'Hubo un error en el proceso, por favor inténtalo nuevamente',
  LABEL_RESET_MAIL_NOT_FOUND: 'No hemos encontrado este correo, por favor verifica tus datos',
  LABEL_RESET_PASSWORD_1:'Nueva contraseña',
  LABEL_RESET_PASSWORD_1_ERROR:'Tu contraseña debe contener 6 caracteres mínimo y contener a lo menos una letra, una cifra y un carácter especial',
  LABEL_RESET_PASSWORD_2:'Confirmación de contraseña',
  LABEL_RESET_PASSWORD_2_ERROR:'Por favor repite tu nueva contraseña',
  LABEL_RESET_PASSWORD_ANSWER_ERROR: 'Por favor indica la respuesta correcta',
  LABEL_RESET_PASSWORD_CONTENT:'No hay problema solo indica tu correo y te mandamos un vinculo para reiniciarlo',
  LABEL_RESET_PASSWORD_MAIL_ERROR: 'Por favor indica un mail válido',
  LABEL_RESET_PASSWORD_RESET_DONE:'Hemos recibido tu pedido para reiniciar tu contraseña, consulta tus correos para seguir con el proceso',
  LABEL_RESET_PASSWORD_RESOLVE:'Por favor confirma que eres humano contestando esta pregunta',
  LABEL_RESET_PASSWORD_TITLE:'Olvidaste tu contraseña ?',
  LABEL_RESET_PASSWORD_TOOLTIP_1:'Por favor indica tu nueva contraseña',
  LABEL_RESET_PASSWORD_TOOLTIP_2:'Por favor repita tu nueva contraseña',
  LABEL_RESET_QUESTION_COMPARE: 'Cuál es el numero mas grande entre {0} y {1}',
  LABEL_RESET_QUESTION_SUM: 'Cuál es el resultado de {0} + {1}',
  LABEL_USERS_HEADER_ACTIONS: 'Acción',
  LABEL_USERS_HEADER_CONNECTIONS: 'Conexiones',
  LABEL_USERS_HEADER_DOB: 'Fecha nacimiento',
  LABEL_USERS_HEADER_GENDER: 'Genero',
  LABEL_USERS_HEADER_NAME: 'Nombre visible',
  LABEL_USERS_HEADER_PLATFORM: 'Plataforma',
  LABEL_USERS_HEADER_STATUS: 'Estado',
  LABEL_USERS_SEARCH: 'Buscar usuarios',
  LABEL_WARNING_ADVERTISING: 'Por favor, complete el formulario de publicidad para activar la funcionalidad',
  LABEL_YES: 'Si'
};
export default dictionary;
