import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Badge,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUsersAction, updateUserAction } from "../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import strings from "../i18n/language";
import BlockIcon from "@mui/icons-material/Block";
import dayjs from "dayjs";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

const getBadgeColor = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "inactive":
      return "default";
    case "suspended":
      return "error";
    default:
      return "warning";
  }
};

const Users = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const isLoadingUsers = useSelector((state) => state.user.isLoadingUsers);

  useEffect(() => {
    if (selectedCompany) dispatch(getUsersAction(selectedCompany.id));
  }, [selectedCompany, dispatch]);

  return (
    <Paper sx={{ p: 2 }}>
      {!users ||
        (isLoadingUsers && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
            }}
          />
        ))}
      {users && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="Users table">
            <TableHead>
              <TableRow>
                <TableCell>{strings.LABEL_USERS_HEADER_NAME}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_GENDER}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_DOB}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_PLATFORM}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_CONNECTIONS}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_STATUS}</TableCell>
                <TableCell>{strings.LABEL_USERS_HEADER_ACTIONS}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!users.length && (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={4}>
                    {strings.LABEL_NO_RESULTS}
                  </TableCell>
                </TableRow>
              )}
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.displayName ? (
                      user.displayName
                    ) : (
                      <BlockIcon color="disabled" />
                    )}
                  </TableCell>
                  <TableCell>
                    {user.gender ? (
                      user.gender === "woman" ? (
                        <FemaleIcon
                          style={{ color: theme.palette.text.secondary }}
                        />
                      ) : (
                        <MaleIcon
                          style={{ color: theme.palette.text.secondary }}
                        />
                      )
                    ) : (
                      <BlockIcon color="disabled" />
                    )}
                  </TableCell>
                  <TableCell>
                    {user.birthDate ? (
                      dayjs(user.birthDate).format("LL")
                    ) : (
                      <BlockIcon color="disabled" />
                    )}
                  </TableCell>
                  <TableCell>
                    {user.type.charAt(0).toUpperCase() + user.type.slice(1)}
                  </TableCell>
                  <TableCell>
                    {user.connections ? user.connections : 0}
                  </TableCell>
                  <TableCell>
                    <Badge
                      color={getBadgeColor(user.proStatus)}
                      variant="dot"
                    ></Badge>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon />
                    </IconButton>
                    {user.proStatus === "active" && (
                      <IconButton
                        onClick={() => {
                          dispatch(
                            updateUserAction({
                              id: user.id,
                              proStatus: "waiting",
                            })
                          );
                        }}
                        aria-label="block"
                      >
                        <BlockIcon />
                      </IconButton>
                    )}
                    {user.proStatus === "waiting" && (
                      <IconButton
                        aria-label="allow"
                        onClick={() => {
                          dispatch(
                            updateUserAction({
                              id: user.id,
                              proStatus: "active",
                            })
                          );
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default Users;
