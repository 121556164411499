import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressesAction,
  cleanAddressAction,
  getAddressAction,
} from "../../redux/actions/address";
import strings from "../../i18n/language";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AddressDelete from "../../dialog/AddressDelete";
import LinearProgress from "@mui/material/LinearProgress";

const LocalsList = () => {
  const addresses = useSelector((state) => state.address.addresses);
  const isLoadingAddresses = useSelector(
    (state) => state.address.isLoadingAddresses
  );
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addressDeleteId, setAddressDeleteId] = useState(null);

  useEffect(() => {
    if (selectedCompany) dispatch(getAddressesAction(selectedCompany.id));
  }, [selectedCompany, dispatch]);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        {!addresses ||
          (isLoadingAddresses && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                width: "100%",
              }}
            />
          ))}
        {addresses && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="Locals table">
              <TableHead>
                <TableRow>
                  <TableCell>{strings.LABEL_LOCALS_HEADER_NAME}</TableCell>
                  <TableCell>{strings.LABEL_LOCALS_HEADER_ADDRESS}</TableCell>
                  <TableCell>
                    {strings.LABEL_LOCALS_HEADER_PHONE_NUMBER}
                  </TableCell>
                  <TableCell>{strings.LABEL_LOCALS_HEADER_ACTIONS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((local) => (
                  <TableRow key={local.id}>
                    <TableCell component="th" scope="row">
                      {local.label}
                    </TableCell>
                    <TableCell>{local.address}</TableCell>
                    <TableCell>{local.phoneNumber}</TableCell>
                    <TableCell>
                      {" "}
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setAddressDeleteId(local.id);
                          setOpenModalDelete(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          dispatch(getAddressAction(local.id));
                          navigate("/locals/edit");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Fab
        style={{ position: "absolute", bottom: 20, right: 20 }}
        color="primary"
        aria-label="add"
        onClick={() => {
          dispatch(cleanAddressAction());
          navigate("/locals/edit");
        }}
      >
        <AddIcon />
      </Fab>
      <AddressDelete
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        addressDeleteId={addressDeleteId}
      ></AddressDelete>
    </>
  );
};

export default LocalsList;
