import { ADDRESS } from "../constants";

const initialState = {
  addresses: null,
  address: null,
  isLoadingAddress: false,
  isLoadingAddresses: false,
  isLoadingCreateAddress: false,
  isLoadingUpdateAddress: false,
  isLoadingDeleteAddress: false,
  error: null,
};

const addressReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADDRESS.GET_ADDRESS:
      return {
        ...state,
        isLoadingAddress: true,
      };
    case ADDRESS.GET_ADDRESS_FAILURE:
      return {
        ...state,
        isLoadingAddress: false,
        error: action.payload,
      };
    case ADDRESS.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingAddress: false,
        address: action.payload,
        error: null,
      };
    case ADDRESS.GET_ADDRESSES:
      return {
        ...state,
        isLoadingAddresses: true,
      };
    case ADDRESS.GET_ADDRESSES_FAILURE:
      return {
        ...state,
        isLoadingAddresses: false,
        error: action.payload,
      };
    case ADDRESS.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        isLoadingAddresses: false,
        addresses: action.payload,
        error: null,
      };
    case ADDRESS.CREATE_ADDRESS:
      return {
        ...state,
        isLoadingCreateAddress: true,
      };
    case ADDRESS.CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        isLoadingCreateAddress: false,
        error: action.payload,
      };
    case ADDRESS.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingCreateAddress: false,
        address: action.payload,
        error: null,
      };
    case ADDRESS.UPDATE_ADDRESS:
      return {
        ...state,
        isLoadingUpdateAddress: true,
      };
    case ADDRESS.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        isLoadingUpdateAddress: false,
        error: action.payload,
      };
    case ADDRESS.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingUpdateAddress: false,
        address: action.payload,
        error: null,
      };
    case ADDRESS.DELETE_ADDRESS:
      return {
        ...state,
        isLoadingDeleteAddress: true,
      };
    case ADDRESS.DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        isLoadingDeleteAddress: false,
        error: action.payload,
      };
    case ADDRESS.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingDeleteAddress: false,
        error: null,
      };
    case ADDRESS.CLEAN_ADDRESS:
      return {
        ...state,
        address: null,
      };
    default:
      return state;
  }
};

export default addressReducer;
