import React, { useState, useEffect } from "react";
import { Tooltip, Grid, Paper, Typography, IconButton } from "@mui/material";
import Fab from "@mui/material/Fab";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../form/CustomTextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import {
  createAddressesAction,
  updateAddressesAction,
} from "../../redux/actions/address";
import strings from "../../i18n/language";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

const LocalsEdit = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState(null);
  const [addressName, setAddressName] = useState(null);
  const [errorAddressName, setErrorAddressName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [errorPhone, setErrorPhone] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [errorAddressId, setErrorAddressId] = useState(null);
  const address = useSelector((state) => state.address.address);
  const selectedCompany = useSelector((state) => state.login.selectedCompany);
  const [valid, setValid] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (address) {
      setName(address.label);
      setAddressName(address.address);
      setPhone(address.phoneNumber);
      setAddressId(address.addressId);
    }
  }, [address]);

  useEffect(() => {
    if (errorName || errorAddressName || errorPhone || errorAddressId) {
      setValid(false);
    } else setValid(true);
  }, [errorName, errorAddressName, errorPhone, errorAddressId]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()%]+$/i;
    if (name && name.length > 4 && name.length < 30) {
      if (alphanumericRegex.test(name)) {
        setErrorName(false);
      } else {
        setErrorName(true);
      }
    } else setErrorName(true);
  }, [name]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()%]+$/i;
    if (addressName && addressName.length > 4 && addressName.length < 60) {
      if (alphanumericRegex.test(addressName)) {
        setErrorAddressName(false);
      } else {
        setErrorAddressName(true);
      }
    } else setErrorAddressName(true);
  }, [addressName]);

  useEffect(() => {
    const alphanumericRegex = /^(tel:|whatsapp:)\+?[0-9]{1,15}$/i;
    if (phone) {
      if (alphanumericRegex.test(phone)) {
        setErrorPhone(false);
      } else {
        setErrorPhone(true);
      }
    } else setErrorPhone(true);
  }, [phone]);

  useEffect(() => {
    const alphanumericRegex = /^[A-Za-z0-9]{16,20}$/i;
    if (addressId) {
      if (alphanumericRegex.test(addressId)) {
        setErrorAddressId(false);
      } else {
        setErrorAddressId(true);
      }
    } else setErrorAddressId(true);
  }, [addressId]);

  const save = () => {
    if (address && address.id)
      dispatch(
        updateAddressesAction({
          ...address,
          ...{
            label: name,
            address: addressName,
            phoneNumber: phone,
            addressId: addressId,
          },
        })
      );
    else
      dispatch(
        createAddressesAction({
          label: name,
          address: addressName,
          phoneNumber: phone,
          addressId: addressId,
          company_id: selectedCompany.id,
        })
      );
    navigate("/locals");
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="back"
        style={{
          marginRight: 10,
        }}
        onClick={() => {
          navigate("/locals");
        }}
      >
        <ArrowBackIcon />
      </Fab>
      <Paper
        sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
      >
        <Grid container justifyContent="center" margin="normal" mt={2}>
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="label"
              label={strings.LABEL_LOCALS_NAME}
              name="label"
              value={name}
              onChange={setName}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_NAME_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {name && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorName ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_NAME_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="label"
              label={strings.LABEL_LOCALS_ADDRESS}
              name="label"
              value={addressName}
              onChange={setAddressName}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_ADDRESS_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {addressName && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorAddressName ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_ADDRESS_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="phone"
              label={strings.LABEL_LOCALS_PHONE}
              name="phone"
              value={phone}
              onChange={setPhone}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_PHONE_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {phone && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorPhone ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_PHONE_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="address"
              label={strings.LABEL_LOCALS_ADDRESS_ID}
              name="address"
              value={addressId}
              onChange={setAddressId}
              autoFocus
            />
            <Tooltip title={strings.LABEL_LOCALS_ADDRESS_ID_TOOLTIP}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {addressId && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorAddressId ? "error" : "success.main"}>
                {strings.LABEL_LOCALS_ADDRESS_ID_ERROR}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <div
        style={{
          alignSelf: "flex-end",
          flexDirection: "column",
          display: "flex",
          marginLeft: 10,
        }}
      >
        <Fab color="error" aria-label="cancel" disabled={true}>
          <CancelIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            marginTop: 10,
          }}
          onClick={save}
          disabled={!valid}
        >
          <SaveIcon />
        </Fab>
      </div>
    </>
  );
};

export default LocalsEdit;
