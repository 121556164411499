import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompanyAction,
  getCompanyAction,
  updateCompanyAction,
} from "../../redux/actions/company";
import CustomTextField from "../../form/CustomTextField";
import CustomSelectField from "../../form/CustomSelectField";
import strings from "../../i18n/language";
import { useTheme } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import CompanyPicture from "../../dialog/CompanyPicture";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import getConfig from "../../config";
let config = getConfig();
let baseUrl = `${config.PROTOCOL}${config.SERVER}:${config.PORT}`;

const listFeedback = [
  { value: true, label: strings.LABEL_YES },
  { value: false, label: strings.LABEL_NO },
];

const listPublic = [
  { value: true, label: strings.LABEL_COMPANIES_PUBLIC },
  { value: false, label: strings.LABEL_COMPANIES_PRIVATE },
];

const CompanyEdit = () => {
  // State for editable fields
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [facebookUrl, setFacebookUrl] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [activateFeedback, setActivateFeedback] = useState(false);
  const [instagramUrl, setInstagramUrl] = useState(null);
  const [giftExpiration, setGiftExpiration] = useState(null);
  const [pointExpiration, setPointExpiration] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorUrlFacebook, setErrorUrlFacebook] = useState(false);
  const [errorUrlInstagram, setErrorUrlInstagram] = useState(false);
  const [errorGiftExpiration, setErrorGiftExpiration] = useState(false);
  const [errorPointExpiration, setErrorPointExpiration] = useState(false);
  const [errorRequirement, setErrorRequirement] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [openModalPicture, setOpenModalPicture] = useState(false);
  const [valid, setValid] = useState(false);
  const company = useSelector((state) => state.company.company);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  /*
  useEffect(() => {
    if (!company) navigate("/companies");
  }, [company]);
*/
  useEffect(() => {
    if (company) {
      setName(company.name);
      setFacebookUrl(company.facebook);
      setActivateFeedback(company.giftFeedback);
      setInstagramUrl(company.instagram);
      setGiftExpiration(company.giftExpiration);
      setPointExpiration(company.cardExpiration);
      setRequirement(company.requirement);
      setCode(company.code);
    } else {
      if (user) dispatch(getCompanyAction(user.company_id));
    }
  }, [company, user, dispatch]);

  useEffect(() => {
    if (
      errorName ||
      errorGiftExpiration ||
      errorPointExpiration ||
      errorUrlFacebook ||
      errorUrlInstagram ||
      errorCode ||
      errorRequirement
    ) {
      setValid(false);
    } else setValid(true);
  }, [
    errorName,
    errorGiftExpiration,
    errorPointExpiration,
    errorUrlFacebook,
    errorUrlInstagram,
    errorCode,
    errorRequirement,
  ]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()%]+$/i;
    if (name && name.length > 4 && name.length < 60) {
      if (alphanumericRegex.test(name)) {
        setErrorName(false);
      } else {
        setErrorName(true);
      }
    } else setErrorName(true);
  }, [name]);

  useEffect(() => {
    if (facebookUrl) {
      const facebookUrlRegex =
        /^https:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+$/i;
      if (facebookUrlRegex.test(facebookUrl)) {
        setErrorUrlFacebook(false);
      } else {
        setErrorUrlFacebook(true);
      }
    } else setErrorUrlFacebook(false);
  }, [facebookUrl]);

  useEffect(() => {
    if (picture) {
      const newBlobUrl = URL.createObjectURL(picture);
      setPictureUrl(newBlobUrl);
    }
  }, [picture]);

  useEffect(() => {
    if (instagramUrl) {
      const instagramRegex =
        /^https:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.-]+$/i;
      if (instagramRegex.test(instagramUrl)) {
        setErrorUrlInstagram(false);
      } else {
        setErrorUrlInstagram(true);
      }
    } else setErrorUrlInstagram(false);
  }, [instagramUrl]);

  useEffect(() => {
    const giftExpirationRegex = /^(?:[4-9]|\d{2,})$/i;
    if (giftExpirationRegex.test(giftExpiration)) {
      setErrorGiftExpiration(false);
    } else {
      setErrorGiftExpiration(true);
    }
  }, [giftExpiration]);

  useEffect(() => {
    const pointExpirationRegex = /^(?:[4-9]|\d{2,})$/i;
    if (pointExpirationRegex.test(pointExpiration)) {
      setErrorPointExpiration(false);
    } else {
      setErrorPointExpiration(true);
    }
  }, [pointExpiration]);

  useEffect(() => {
    const codeRegex = /^[A-Z]{3}\d{4}$/i;
    if (codeRegex.test(code)) {
      setErrorCode(false);
    } else {
      setErrorCode(true);
    }
  }, [code]);

  useEffect(() => {
    const requirementRegex = /^[a-zA-Z0-9 $.\u00C0-\u00FF]+$/i;
    if (requirementRegex.test(requirement)) {
      setErrorRequirement(false);
    } else {
      setErrorRequirement(true);
    }
  }, [requirement]);

  const cancel = () => {
    if (company) {
      setName(company.name);
      setFacebookUrl(company.facebook);
      setInstagramUrl(company.instagram);
      setGiftExpiration(company.giftExpiration);
      setPointExpiration(company.pointExpiration);
    } else {
      setName(null);
      setFacebookUrl(null);
      setInstagramUrl(null);
      setGiftExpiration(null);
      setPointExpiration(null);
    }
  };

  const save = () => {
    let formData = new FormData();
    if (picture) {
      formData.append("picture", picture);
    }
    formData.append("name", name);
    formData.append("code", code);
    formData.append("public", isPublic);
    if (facebookUrl) formData.append("facebook", facebookUrl);
    if (instagramUrl) formData.append("instagram", instagramUrl);
    formData.append("giftExpiration", giftExpiration);
    formData.append("giftFeedback", activateFeedback);
    formData.append("cardExpiration", pointExpiration);
    formData.append("requirement", requirement);
    if (company && company.id)
      dispatch(updateCompanyAction(formData, company.id));
    else dispatch(createCompanyAction(formData));
    navigate("/companies");
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="back"
        style={{
          marginRight: 10,
        }}
        onClick={() => {
          navigate("/companies");
        }}
      >
        <ArrowBackIcon />
      </Fab>
      <Paper
        sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
      >
        <Grid container justifyContent="center" margin="normal" mt={2}>
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              id="name"
              label={strings.LABEL_INPUT_NAME}
              name="name"
              value={name}
              onChange={setName}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_NAME}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {name && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorName ? "error" : "success.main"}>
                {strings.LABEL_COMPANIES_NAME_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomSelectField
              onChange={setIsPublic}
              margin="normal"
              title={strings.LABEL_NOTIFICATION_TYPE}
              id={"public"}
              value={isPublic}
              defaultValues={listPublic}
              defaultValue={true}
            ></CustomSelectField>
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_TYPE}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <CustomSelectField
              margin="normal"
              onChange={setActivateFeedback}
              title={strings.LABEL_COMPANIES_FEEDBACK}
              id={"feedback"}
              value={activateFeedback}
              defaultValues={listFeedback}
              defaultValue={false}
            ></CustomSelectField>
            <Tooltip title={strings.LABEL_COMPANIES_FEEDBACK_TYPE}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="facebook-url"
              label={strings.LABEL_INPUT_FACEBOOK_URL}
              name="facebook-url"
              value={facebookUrl}
              onChange={setFacebookUrl}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_FACEBOOK}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {facebookUrl && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorUrlFacebook ? "error" : "success.main"}>
                {strings.LABEL_COMPANIES_URL_FACEBOOK_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="instagram-url"
              label={strings.LABEL_INPUT_INSTAGRAM_URL}
              name="instagram-url"
              value={instagramUrl}
              onChange={setInstagramUrl}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_INSTAGRAM}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {instagramUrl && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorUrlInstagram ? "error" : "success.main"}>
                {strings.LABEL_COMPANIES_URL_INSTAGRAM_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="gift-expiration"
              label={strings.LABEL_INPUT_GIFT_EXPIRATION}
              name="gift-expiration"
              value={giftExpiration}
              onChange={setGiftExpiration}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_GIFT_EXPIRATION}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {giftExpiration && (
            <Grid item xs={8} mt={1}>
              <Typography
                color={errorGiftExpiration ? "error" : "success.main"}
              >
                {strings.LABEL_COMPANIES_GIFT_EXPIRATION_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="card-expiration"
              label={strings.LABEL_INPUT_CARD_EXPIRATION}
              name="card-expiration"
              value={pointExpiration}
              onChange={setPointExpiration}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_POINT_EXPIRATION}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {pointExpiration && (
            <Grid item xs={8} mt={1}>
              <Typography
                color={errorPointExpiration ? "error" : "success.main"}
              >
                {strings.LABEL_COMPANIES_POINT_EXPIRATION_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="code"
              label={strings.LABEL_INPUT_CODE}
              name="code"
              value={code}
              onChange={setCode}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_CODE}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {code && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorCode ? "error" : "success.main"}>
                {strings.LABEL_COMPANIES_CODE_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} mt={2} display="flex">
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="requirement"
              label={strings.LABEL_INPUT_REQUIREMENT}
              name="requirement"
              value={requirement}
              onChange={setRequirement}
              autoFocus
            />
            <Tooltip title={strings.LABEL_COMPANIES_TOOLTIP_REQUIREMENT}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {requirement && (
            <Grid item xs={8} mt={1}>
              <Typography color={errorRequirement ? "error" : "success.main"}>
                {strings.LABEL_COMPANIES_REQUIREMENT_ERROR}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <Typography variant="h6" color="rgba(255, 255, 255, 0.7)">
              {strings.LABEL_INPUT_IMAGE}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenModalPicture(true);
              }}
            >
              <EditIcon style={{ color: "white" }} />
            </IconButton>
            {pictureUrl && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setPicture(null);
                  setPictureUrl(null);
                }}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{
              marginBottom: 50,
              position: "relative",
            }}
          >
            <div
              style={{
                borderRadius: 15,
                border: "1px solid white",
                minHeight: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  backgroundColor: "rgba(122,122,122,.5)",
                  width: "100%",
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                  color: "white",
                }}
              >
                {name ? name : strings.LABEL_COMPANIES_NAME_COMPANY_PICTURE}
              </div>
              {pictureUrl ? (
                <img
                  alt="Company"
                  src={pictureUrl}
                  style={{
                    width: "100%",
                    borderRadius: 15,
                  }}
                ></img>
              ) : company ? (
                company.avatar ? (
                  <img
                    alt="Company"
                    src={baseUrl + "/public/avatars/" + company.avatar}
                    style={{
                      width: "100%",
                      borderRadius: 15,
                    }}
                  ></img>
                ) : (
                  <img
                    alt="Company"
                    src={baseUrl + "/public/avatars/no_image.jpg"}
                    style={{
                      width: "100%",
                      borderRadius: 15,
                    }}
                  ></img>
                )
              ) : (
                <div
                  style={{ color: "white", fontSize: 14, fontStyle: "italic" }}
                >
                  {strings.LABEL_COMPANIES_NO_PICTURE}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          alignSelf: "flex-end",
          flexDirection: "column",
          display: "flex",
          marginLeft: 10,
        }}
      >
        <Fab color="error" aria-label="cancel" onClick={cancel} disabled={true}>
          <CancelIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            marginTop: 10,
          }}
          onClick={save}
          disabled={!valid}
        >
          <SaveIcon />
        </Fab>
      </div>
      <CompanyPicture
        open={openModalPicture}
        setOpen={setOpenModalPicture}
        title={strings.LABEL_COMPANIES_CHANGE_PICTURE}
        setPicture={setPicture}
      ></CompanyPicture>
    </>
  );
};

export default CompanyEdit;
